
export default {
  name: "IndexPage",
  head() {
    return {
      title: "خرید تتر | فروش تتر | تبدیل تتر",
      meta: [
        {
          hid: "description",
          name: "description",
          content:
            "تتر اکسچنج | بهترین صرافی امن و معتبر آنلاین فروش و خرید تتر در ایران با تسویه حساب انی",
        },
        {
          hid: "keywords",
          name: "keywords",
          content:
            "صرافی آنلاین, خرید ارز دیجیتال, فروش ارز دیجیتال, صرافی ارز دیجیتال, صرافی آنلاین معتبر, خرید و فروش ارز دیجیتال,  صرافی ارز دیجیتال ایرانی, خرید و فروش ارز دیجیتال در ایران, صرافی انلاین خرید و فروش ارز دیجیتال, فروش تتر, خرید تتر, فروش و خرید تتر, خرید و فروش تتر, صرافی تتر",
        },
        { hid: "logo", name: "image", content: "~/layouts/logos/default.svg" },
        { hid: "robots", name: "robots", content: "index,follow" },
        { hid: "googlebot", name: "googlebot", content: "index,follow" },
      ],
      link: [
        {
          hid: "canonical",
          rel: "canonical",
          hreflang: "fa",
          href: "https://tether-ex.com",
        },
      ],
    };
  },
  data() {
    return {
      features: [
        {
          image: "/layouts/images/features/buy-sell.png",
          imgAlt: "fast-trade-icon",
          title: "معامله سریع و آسان",
          desc: "تجربه خرید و فروش متفاوت در کنار سرعت و امنیت",
        },
        {
          image: "/layouts/images/features/best-rate.png",
          imgAlt: "best-rate-icon",
          title: "بهترین نرخ تبادل",
          desc: "ارائه بهترین نرخ تبادل تتر در تتر اکسچنج",
        },
        {
          image: "/layouts/images/features/wallet.png",
          imgAlt: "wallet-limitless-icon",
          title: "کیف پول بدون محدودیت",
          desc: "انجام معاملات سنگین و کسب درامد با استفاده از کیف پول ارزی تتر اکسچنج",
        },
        {
          image: "/layouts/images/features/security.png",
          imgAlt: "security-icon",
          title: "تضمین امنیت",
          desc: "صرافی ارز دیجیتال تتر اکسچنج، امنیت معاملات شما را تضمین می کند",
        },
      ],
      buySellTutorial: {
        slider: {
          total: 3,
          active: 0,
          timer: null,
          items: {
            buy: [
              {
                step: "مرحله اول",
                title: "ورود به حساب کاربری",
                image: require("~/assets/images/tutorials/buy/1.png"),
                desc: "با کلیک بر روی دکمه ورود در سمت چپ نوار بالای صفحه، وارد حساب کاربری خود شوید.",
              },
              {
                step: "مرحله دوم",
                title: "تعیین تعداد تتر درخواستی",
                image: require("~/assets/images/tutorials/buy/2.png"),
                desc: "در باکس مورد نظر تعداد تتر درخواستی را وارد کرده و بر روی دکمه خرید تتر کلیک نمایید.",
              },
              {
                step: "مرحله سوم",
                title: "انتخاب روش دریافت تتر",
                image: require("~/assets/images/tutorials/buy/3.png"),
                desc: "یکی از گزینه های مورد نظر را به عنوان روش دریافت تتر انتخاب نمایید.",
              },
              {
                step: "مرحله چهارم",
                title: "تایید پرداخت نهایی",
                image: require("~/assets/images/tutorials/buy/4.png"),
                desc: "پیش فاکتور خود را بررسی کرده و در صورت تایید خرید خود را تکمیل نمایید.",
              },
            ],
            sell: [
              {
                step: "مرحله اول",
                title: "ورود به حساب کاربری",
                image: require("~/assets/images/tutorials/sell/1.png"),
                desc: "با کلیک بر روی دکمه ورود درسمت چپ نوار بالای صفحه،وارد حساب کاربری خود شوید.",
              },
              {
                step: "مرحله دوم",
                title: "تعیین تعداد تتر",
                image: require("~/assets/images/tutorials/sell/2.png"),
                desc: "در باکس مورد نظر تعداد تتر جهت فروش را وارد کرده و بر روی دکمه فروش تتر کلیک نمایید.",
              },
              {
                step: "مرحله سوم",
                title: "انتخاب روش دریافت تومان",
                image: require("~/assets/images/tutorials/sell/3.png"),
                desc: "یکی از گزینه های مورد نظر را به عنوان روش دریافت تومان انتخاب نمایید.",
              },
              {
                step: "مرحله چهارم",
                title: "تایید و انتقال ارز",
                image: require("~/assets/images/tutorials/sell/4.png"),
                desc: "پیش فاکتور فروش خود را بررسی کرده و در صورت تایید، فروش خود را تکمیل نمایید.",
              },
            ],
          },
        },
        active: 0,
      },
      tabButtons: [
        { id: 0, name: "نحوه خرید" },
        { id: 1, name: "نحوه فروش" },
      ],
      tabButtonsTransaction: [
        { id: 0, name: "خرید تتر" },
        { id: 1, name: "فروش تتر" },
        // { id: 2, name: "تبدیل لیر به تومان" },
      ],
      selectedTransactionType: 0,
      faqs: [
        {
          question: "تتر(USDT)چیست؟",
          answer:
            "تتر (Tether) با واحد اختصاری USDT یک ارز دیجیتال با پشتوانه دلار است که قیمتی همیشه ثابت دارد. بهای هر تتر، همواره برابر با یک دلار ایالات متحده آمریکا است. در حال حاضر، تتر یکی از محبوب‌ترین استیبل کوینStable Coin))های بازار است که معامله‌گران به‌منظور حفظ سرمایه‌های خود در مقابل نوسانات بازار، از آن استفاده می‌کنند.",
        },
        {
          question: "تتر ترون چیست؟",
          answer:
            "USDT-TRC 20مبتنی بر بلاک چین ترون که در حال حاضر نسبت به بقیه شبکه انتقال تتر فراگیر تر بوده و درمیان کاربران از محبوبیت بیشتری برخوردار است.",
        },
        {
          question: "چگونه تتر بخرم ؟",
          answer:
            "خرید تتر از تتراکسچنج با کارت بانکی بنام شخص احراز شده تا سقف روزانه 25 میلیون تومان و افزایش اعتبار کیف پول تومانی به صورت ۲۴ساعته و اتوماتیک از وب‌سایت یا اپلیکیشن تتراکسچنج انجام می‌شود.",
        },
        {
          question: "چرا احراز هویت نیاز است؟",
          answer:
            "یکی از ویژگی‌های شبکه بلاکچین و بیت کوین غیرقابل برگشت بودن تراکنش‌های آنها است. فروشندگان برای کاهش ریسک موجود در خرید و فروش رمز ارز ها و همچنین عدم سو استفاده کلاهبرداران اینترنتی بر اساس قوانین موجود در بازارهای مالی دنیا نیاز است که هر فرد تنها با هویت و کارت بانکی خود اقدام به معامله ،واریز و برداشت نماید تا از این گونه مشکلات جلوگیری شود .",
        },
        {
          question: "چطور به تتر اکسچنج اعتماد کنم؟",
          answer:
            "به‌طورکلی، اعتبار پلتفرم تبادل در اکوسیستم رمزارزها به چند شاخص مهم مانند سابقه فعالیت، اصالت، نرخ تبادل رمزارز، پشتیبانی ۲۴ساعته و امنیت بستگی دارد. شما در ابتدا می توانید با درخواست های کوچکتر شروع کنید و به مرور پس از کسب اطمینان از تتر اکسچنج اقدام به درخواست های بزرگتر نمایید.",
        },
      ],
      price: {
        usdt: 0,
        lowPrice: 0,
        hightPrice: 0,
        percentageDifference: 0,
      },
      lirPrice: {
        lir: 0,
        lowLirPrice: 0,
        hightLirPrice: 0,
        percentageDifference: 0,
      },
      posts: {},
    };
  },
  watch: {
    "buySellTutorial.active"(newVal) {
      this.buySellTutorial.slider.active = 0;
      this.toggleTutorialSlider();
    },
  },
  async fetch() {
    this.$store.dispatch("overlay/toggleOverlay");
    try {
      const response = await this.$axios.$get("/v1/home");
      this.price = response.price;
      this.lirPrice = response.lirPrice;
      this.posts = response.posts;
      this.$store.dispatch("usdtConfig/set", this.price);
      this.$store.dispatch("lirConfig/set", this.lirPrice);
    } catch (err) {
      console.log(err);
    } finally {
      this.$store.dispatch("overlay/toggleOverlay");
    }
  },
  beforeMount() {
    this.toggleTutorialSlider();
  },
  mounted() {
    const self = this;
    setInterval(function () {
      self.getData();
    }, 20000);
  },
  methods: {
    async getData() {
      const response = await this.$axios.$get("/v1/home");
      this.price = response.price;
      this.posts = response.posts;
      this.lirPrice = response.lirPrice;
      this.$store.dispatch("usdtConfig/set", this.price);
      this.$store.dispatch("lirConfig/set", this.lirPrice);
    },
    toggleTutorialSlider() {
      clearInterval(this.buySellTutorial.slider.timer);
      this.buySellTutorial.slider.timer = setInterval(() => {
        if (
          this.buySellTutorial.slider.active + 1 <=
          this.buySellTutorial.slider.total
        ) {
          this.buySellTutorial.slider.active++;
        } else {
          this.buySellTutorial.slider.active = 0;
        }
      }, 5000);
    },
    nextSlide() {
      if (
        this.buySellTutorial.slider.active + 1 <=
        this.buySellTutorial.slider.total
      ) {
        this.buySellTutorial.slider.active++;
        this.toggleTutorialSlider();
      } else {
        this.buySellTutorial.slider.active = this.buySellTutorial.slider.total;
        this.toggleTutorialSlider();
      }
    },
    prevSlide() {
      if (this.buySellTutorial.slider.active - 1 >= 0) {
        this.buySellTutorial.slider.active--;
        this.toggleTutorialSlider();
      } else {
        this.buySellTutorial.slider.active = 0;
        this.toggleTutorialSlider();
      }
    },
  },
};
